import { lazy } from "react";

export default [
  {
    path: "/catalog-management/master-catalog",
    component: lazy(() =>
      import(
        "../modules/pages/catalog-management/amazon-catalog/master-catalog/master-catalog"
      )
    ),
    exact: true,
  },
];
