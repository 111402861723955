import Icons from "../../../../components/icons";
import { GetLinks } from "../../../../core";

export default [
  {
    key: "/settings",
    icon: <Icons type="setting" />,
    label: GetLinks("/settings", "Settings"),
  },
];
