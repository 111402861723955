import {
  AppstoreOutlined,
  BarChartOutlined,
  CloudOutlined,
  ShopOutlined,
  TeamOutlined,
  UploadOutlined,
  UserOutlined,
  VideoCameraOutlined,
} from "@ant-design/icons";
import { Layout, theme } from "antd";
import React, { useContext, useEffect, useState } from "react";
import PageRouter from "../../router/index.js";
import { Redirect } from "react-router-dom";
import HeaderEgas from "../layout/header/index.js";
import FooterEgas from "../layout/footer/index.js";
import SidebarEgas from "../layout/sidebar/index.js";
import { useLocation, useHistory } from "react-router-dom";
import { GlobalContext } from "../../commonContext.js";
import { Link } from "react-router-dom/cjs/react-router-dom.min.js";

const { Header, Content, Footer, Sider } = Layout;
const items = [
  UserOutlined,
  VideoCameraOutlined,
  UploadOutlined,
  BarChartOutlined,
  CloudOutlined,
  AppstoreOutlined,
  TeamOutlined,
  ShopOutlined,
].map((icon, index) => ({
  key: String(index + 1),
  icon: React.createElement(icon),
  label: `nav ${index + 1}`,
}));

function Page() {
  const {
    token: { colorBgContainer },
  } = theme.useToken();

  const [collapsed, setCollapsed] = useState(false);
  const [full_path, set_full_path] = useState([]);
  const contextValue = useContext(GlobalContext);

  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    if (location.pathname === "/" && localStorage.getItem("token")) {
      if (localStorage.getItem("user_type") == 1) {
        history.replace("/manage-user");
      } else {
        history.replace("/catalog-management");
      }
    }
    // if (location.pathname === "/" || !localStorage.getItem("token")) {
    //   history.replace("/login");
    // } else {
    //   if (localStorage.getItem("user_type") == 2) {
    //     history.replace("/catalog-management");
    //   } else {
    //     history.replace("/manage-user");
    //   }
    // }
    // if (location.pathname === "/") {
    //   if (localStorage.getItem("user_type") == 1) {
    //     history.replace("/manage-user");
    //   } else {
    //     history.replace("/dashboard");
    //   }
    // }
  }, []);

  useEffect(() => {
    const path_name = location.pathname.split("/");
    contextValue?.updateCommonGlobalVal({
      route_name: path_name?.[1],
    });
    set_full_path(path_name);
  }, [location]);

  if (!localStorage.getItem("token")) {
    return <Redirect to="/login" />;
  }

  return (
    <>
      <div
        className={`app-page flex-column flex-column-fluid ${
          contextValue?.data?.user_type == 2 ? "cus-padd" : ""
        }`}
        id="kt_app_page"
      >
        <HeaderEgas setCollapsed={setCollapsed} collapsed={collapsed} />
        <div
          className="app-wrapper flex-column flex-row-fluid"
          id="kt_app_wrapper"
        >
          {/*begin::Wrapper container*/}
          <div className="app-container container-fluid d-flex flex-grow-1">
            <div
              className="app-main flex-column flex-row-fluid"
              id="kt_app_main"
            >
              <div className="d-flex flex-column flex-column-fluid">
                <div id="kt_app_content" className="app-content pt-4">
                  <div className="app-toolbar-wrapper w-100 mb-8">
                    {/*begin::Wrapper*/}
                    <div className="page-title d-flex justify-content-center flex-column me-5">
                      {/*begin::Title*/}
                      <h1
                        className="d-flex flex-column text-dark fw-bold fs-2 mb-0"
                        style={{ textTransform: "capitalize" }}
                      >
                        {full_path?.[1]?.replaceAll("-", " ")}
                      </h1>
                      {/*end::Title*/}
                      {/*begin::Breadcrumb*/}
                      <ul className="breadcrumb breadcrumb-separatorless fw-semibold fs-7 pt-1">
                        {/*begin::Item*/}
                        <li className="breadcrumb-item text-muted">
                          <Link to={{ pathname: "/dashboard" }}>
                            <span className="text-muted text-hover-primary">
                              Home
                            </span>
                          </Link>
                        </li>
                        {/*end::Item*/}
                        {/*begin::Item*/}

                        {/*end::Item*/}
                        {/*begin::Item*/}

                        {/*end::Item*/}
                        {/*begin::Item*/}

                        {full_path?.map((d, i) => {
                          if (d) {
                            const newPath = full_path
                              .slice(0, i + 1)
                              ?.join("/");
                            // console.log(newPath, i, "-full_path");
                            return (
                              <>
                                <li className="breadcrumb-item">
                                  <span className="bullet bg-gray-300 w-5px h-2px" />
                                </li>
                                {/*end::Item*/}
                                {/*begin::Item*/}
                                <li style={{ paddingLeft: "0.5rem" }}>
                                  {full_path?.length - 1 === i ? (
                                    <span
                                      className={`breadcrumb-item text-dark`}
                                      style={{ textTransform: "capitalize" }}
                                    >
                                      {" "}
                                      {d?.replaceAll("-", " ")}
                                    </span>
                                  ) : (
                                    <Link
                                      to={{ pathname: newPath }}
                                      className={`breadcrumb-item text-muted`}
                                      style={{ textTransform: "capitalize" }}
                                    >
                                      {d?.replaceAll("-", " ")}
                                    </Link>
                                  )}
                                </li>
                              </>
                            );
                          }
                        })}

                        {/*end::Item*/}
                      </ul>
                      {/*end::Breadcrumb*/}
                    </div>
                    {/*end::Wrapper*/}
                  </div>

                  {localStorage.getItem("user_type") == 2 && <SidebarEgas />}

                  <PageRouter />
                </div>
              </div>

              <FooterEgas />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default Page;
