import { Button, Result } from "antd";
import React from "react";
import { useHistory } from "react-router-dom";
import ErrorWrapper from "./style";

export default function (props) {
  // const { status } = props;
  const history = useHistory();

  return (
    <div className="d-flex flex-column flex-column-fluid">
      <div className="app-content flex-column-fluid " id="kt_app_content">
        <div
          id="kt_app_content_container"
          className="app-container container-fluid"
        >
          {1 === 1 ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "50vh",
                fontSize: "150px",
                color: "#443dba",
                fontWeight: "bold",
                textShadow: "9px 0px 3px #adaadf",
              }}
            >
              <label>404</label>
            </div>
          ) : (
            "Hello"
          )}
        </div>
      </div>
    </div>
  );
}
