import axios from "axios";
import { ENDPOINT } from "../config";
import { message } from "antd";

const getToken = () => localStorage.getItem("token");

// Common function for making API calls
export const MakeCommonApiCall = async (
  url,
  method = "get",
  data = null,
  withBearer = false,
  isAdmin = false
) => {
  try {
    const headers = {};

    // Determine Content-Type based on data type
    if (data instanceof FormData) {
      headers["Content-Type"] = "multipart/form-data";
    } else {
      headers["Content-Type"] = "application/json";
    }

    if (withBearer) {
      // Assuming you have a way to retrieve the authentication token
      if (getToken()) {
        headers["Authorization"] = `Bearer ${getToken()}`;
      }
    }
    let url_ = ENDPOINT + url;
    const response = await axios({
      method,
      url: url_,
      data: data ? JSON.stringify(data) : null,
      headers,
    });

    return response.data;
  } catch (error) {
    // Handle errors here (e.g., log them or throw a custom error)
    const newData = JSON.stringify(error);
    const finalData = JSON.parse(newData);
    if (finalData?.status == 401) {
      localStorage.clear();
      return window.location.assign("/login");
    } else {
      const errorData = {
        status: false,
        message: "Something went wrong",
      };
      return errorData;
    }
    // throw { message: error?.message };
  }
};
