import { lazy } from "react";

export default [
  //   {
  //     path: "/callbackads",
  //     component: lazy(() => import("../modules/pages/channel-integration")),
  //     exact: true,
  //   },
  {
    path: "/callback_sp",
    component: lazy(() => import("../modules/pages/channel-integration")),
    exact: true,
  },
  {
    path: "/channel-integration",
    component: lazy(() => import("../modules/pages/channel-integration")),
    exact: true,
  },
];
