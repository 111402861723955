import Icons from "../../../../components/icons";
import { GetLinks } from "../../../../core";

export default [
  {
    key: "/dashboard",
    route_name: "dashboard",
    menu_name: "Dashboard",
    icon: <i className="ki-outline ki-element-11 fs-2x p-0" />,
    label: GetLinks("/dashboard", "Dashboard"),
  },
];
