import React, { useState, createContext } from "react";

const getParsedLocalStorageItem = (key) => {
  const item = localStorage.getItem(key);
  return item !== null && item !== "undefined" ? item : undefined;
};

// export const GlobalSpinnerContext = createContext();
export const GlobalContext = createContext();
const GlobalCommonContextProvider = (props) => {
  const [commonGlobalVal, setCommonGlobalVal] = useState({
    window_: window,
    user_data: localStorage.getItem("user_data"),
    user_type: localStorage.getItem("user_type"),
    route_name: "",
    isAdmin: localStorage.getItem("isAdmin"),
    admin_data: localStorage.getItem("admin_data"),
    selected_platform: localStorage.getItem("selected_platform"),
    platforms: localStorage.getItem("platforms") || [],
    company_list: localStorage.getItem("company_list") || [],
    // company_id: localStorage.getItem("company_id") ,
    company_id: getParsedLocalStorageItem("company_id"),
    // module_list: localStorage.getItem("module_list")
    //   ? JSON.parse(localStorage.getItem("module_list"))
    //   : [],
  });

  return (
    <GlobalContext.Provider
      value={{
        data: commonGlobalVal,
        updateCommonGlobalVal: (rest) => {
          setCommonGlobalVal({ ...commonGlobalVal, ...rest });
        },
      }}
    >
      {props.children}
    </GlobalContext.Provider>
  );
};

export default GlobalCommonContextProvider;
