import { useContext } from "react";
import { GlobalContext } from "../commonContext";
import { Skeleton, notification } from "antd";
// user_type === 1 ? 'admin' : user
// alt + shift + o to remove unused import
// export const ENDPOINT = "http://52.66.24.100/egas-php/api/v1/";
export const ENDPOINT = "https://api.egas.in/api/v1/";
export const IMAGE = "https://api.egas.in";

export const DateFormat = "MMM DD, YYYY";
export const DefaultPerPage = 10;
export const pageDropdown = [10, 25, 50, 75, 100];
export const getUserId = () => {
  return JSON.parse(localStorage.getItem("user_data"))?.id;
};

export const urlDecode = (url) => {
  let newObj = {};
  url?.search
    ?.split("?")[1]
    ?.split("&")
    ?.forEach((e) => {
      const obj = e?.split("=");
      newObj = { ...newObj, [obj[0]]: obj[1] };
    });
  return newObj || {};
};

export const NumberWithCommas = (n) => {
  const num_ = parseFloat(n || 0);
  if (num_) {
    if (Number(num_) === num_ && num_ % 1 !== 0) {
      return parseFloat(num_)
        .toFixed(2)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
    return parseInt(num_)
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  return num_;
};

export const ConvertParams = (values) => {
  return Object.entries(
    Object.entries(values)
      .filter(([_, value]) => value)
      .reduce((acc, [key, value]) => {
        acc[key] = encodeURIComponent(value);
        return acc;
      }, {})
  )
    ?.map((d, i) => {
      return `${i === 0 ? "?" : "&"}${d[0]}=${d[1] || ""}`;
    })
    ?.join("");
};

export const SetUserInformation = (contextValue, response, flag) => {
  // -------------------------------------------  Switch User ------------------------------------------
  if (flag === "switch_user") {
    let adminToken = localStorage.getItem("token");
    let adminData = localStorage.getItem("user_data");
    const data = {
      token: adminToken,
      user_data: adminData,
    };
    contextValue?.updateCommonGlobalVal({
      admin_data: JSON.stringify(data),
      isAdmin: 1,
      user_data: JSON.stringify(response?.response_data?.user_data),
      user_type: response?.response_data?.user_data?.user_type,
      platforms: JSON.stringify(response?.response_data?.user_data?.platforms),
      selected_platform: response?.response_data?.user_data?.selected_platform,
      company_list: JSON.stringify(response?.company_list),
      company_id: response?.company_list[0]?.id,
    });
    localStorage.setItem("admin_data", JSON.stringify(data));
    localStorage.setItem("isAdmin", 1);
    localStorage.setItem(
      "platforms",
      JSON.stringify(response?.response_data?.user_data?.platforms)
    );
    localStorage.setItem(
      "selected_platform",
      response?.response_data?.user_data?.selected_platform
    );
    localStorage.setItem("company_id", response?.company_list[0]?.id);
    localStorage.setItem("company_list", JSON.stringify(response.company_list));
  } // -------------------------------------------  Login with User type 2 (User) ------------------------------------------
  else if (
    flag === "login" &&
    response?.response_data?.user_data?.user_type == 2
  ) {
    contextValue?.updateCommonGlobalVal({
      isAdmin: 0,
      user_data: JSON.stringify(response?.response_data?.user_data),
      user_type: response?.response_data?.user_data?.user_type,
      platforms: JSON.stringify(response?.response_data?.user_data?.platforms),
      selected_platform: response?.response_data?.user_data?.selected_platform,
      company_list: JSON.stringify(response?.company_list),
      company_id: response?.company_list[0]?.id,
    });
    localStorage.setItem("isAdmin", 0);
    localStorage.setItem(
      "platforms",
      JSON.stringify(response?.response_data?.user_data?.platforms)
    );
    localStorage.setItem(
      "selected_platform",
      response?.response_data?.user_data?.selected_platform
    );
    localStorage.setItem("company_id", response?.company_list[0]?.id);
    localStorage.setItem("company_list", JSON.stringify(response.company_list));
  } // -------------------------------------------  Login with User type 1 (Admin) ------------------------------------------
  else if (
    flag === "login" &&
    response?.response_data?.user_data?.user_type == 1
  ) {
    contextValue?.updateCommonGlobalVal({
      isAdmin: 0,
      user_data: JSON.stringify(response?.response_data?.user_data),
      user_type: response?.response_data?.user_data?.user_type,
    });
    localStorage.setItem("isAdmin", 0);
  }
  // -------------------------------------------  Common Data ------------------------------------------

  localStorage.setItem(
    "user_data",
    JSON.stringify(response?.response_data?.user_data)
  );
  localStorage.setItem("token", response?.response_data?.auth_token);
  localStorage.setItem(
    "user_type",
    response?.response_data?.user_data?.user_type
  );
  return;
};

export const GetPlatformsList = (platform) => {
  // console.log(platform, " -platform----");
  // const platform = ["Amazon", "Flipkart"];
  const newList = platform?.map((d) => {
    return {
      label: (
        <div>
          <img
            src={`/assets/media/platform_image/${d}.png`}
            style={{ width: "20px" }}
            alt=""
            className="me-3"
          />
          <label>{d}</label>
        </div>
      ),
      value: d,
    };
  });
  return newList;
};

export const DisplayNotification = (
  type,
  title,
  message,
  placement,
  closeIcon
) => {
  if (type === "error") {
    notification.error({
      message: title || "Error",
      description: message || "Something went wrong",
      placement: placement || "topRight", // Set placement to 'topRight'
      // closeIcon: closeIcon || null,
      duration: 5,
      style: {
        backgroundColor: "#ffb7b7", // Change the background color
        borderRadius: "7px",
      },
    });
  } else if (type === "success") {
    notification.success({
      message: title || "Error",
      description: message || "Something went wrong",
      placement: placement || "topRight", // Set placement to 'topRight'
      // closeIcon: closeIcon || null,
      duration: 2,
      style: {
        backgroundColor: "#cdefcb", // Change the background color
        borderRadius: "7px",
      },
    });
  } else if (type === "warning") {
    notification.warning({
      message: title || "Warning",
      description: message || "Something went wrong",
      placement: placement || "topRight", // Set placement to 'topRight'
      // closeIcon: closeIcon || null,
      duration: 5,
      style: {
        backgroundColor: "#fef5e6", // Change the background color
        borderRadius: "7px",
      },
    });
  } else if (type === "info") {
    notification.info({
      message: title || "Info",
      description: message || "Something went wrong",
      placement: placement || "topRight", // Set placement to 'topRight'
      // closeIcon: closeIcon || null,
      duration: 5,
      style: {
        backgroundColor: "#d8e7ff", // Change the background color
        borderRadius: "7px",
      },
    });
  }
};

export const getTableLoading = (numberOfColumns) => {
  const numberOfRows = 6;
  const renderedRows = [...Array(numberOfRows)].map((e, i) => (
    <tr key={i}>
      {[...Array(numberOfColumns)]?.map((d) => {
        return (
          <td>
            <Skeleton.Button active block />
          </td>
        );
      })}
    </tr>
  ));
  return renderedRows;
};
