import React, { useContext } from "react";
import payload from "./lib";
import { GlobalContext } from "../../../commonContext";
import { Link } from "react-router-dom";

const SidebarEgas = (props) => {
  const contextValue = useContext(GlobalContext);
  const route_name = contextValue?.data?.route_name;
  // console.log(route_name, "-route_name");
  return (
    <div className=" bottom-fixed-menu">
      <div className="nav d-flex flex-wrap gap-5 menu-cus justify-content-center">
        {payload?.users?.map((d, i) => {
          return (
            <Link
              id={Math.random()}
              to={{
                pathname: d?.key,
              }}
              key={i}
            >
              <li className="nav-item">
                <span
                  className={`${
                    d?.route_name === route_name
                      ? "active"
                      : d?.route_name === "dashboard" && !route_name
                      ? "active"
                      : ""
                  } nav-link btn btn-active-primary btn-center bg-gray-100 btn-color-gray-800 rounded-1 flex-column overflow-hidden w-175px h-90px pt-7 pt-lg-5 pb-4 `}
                >
                  <div className="nav-icon mb-3">{d?.icon}</div>
                  <span className="fw-bold fs-7 lh-1"> {d?.menu_name}</span>
                </span>
              </li>
            </Link>
          );
        })}

        {/* <li className="nav-item">
          <a
            className="nav-link btn btn-active-primary btn-center bg-gray-100 btn-color-gray-800 rounded-1 flex-column overflow-hidden w-175px h-90px pt-7 pt-lg-5 pb-4 "
            href="dashboard.html"
          >
            <div className="nav-icon mb-3">
              <i className="ki-outline ki-element-11 fs-2x p-0" />
            </div>

            <span className="fw-bold fs-7 lh-1">Dashboard </span>
          </a>
        </li>

        <li className="nav-item">
          <a
            className="nav-link btn btn-active-primary btn-center bg-gray-100 btn-color-gray-800 rounded-1 flex-column overflow-hidden w-175px h-90px pt-7 pt-lg-5 pb-4 "
            href="Order-management.html"
          >
            <div className="nav-icon mb-3">
              <i className="ki-outline ki-basket-ok fs-2x p-0" />
            </div>

            <span className="fw-bold fs-7 lh-1">Order </span>
          </a>
        </li>

        <li className="nav-item">
          <a
            className="nav-link btn btn-active-primary btn-center bg-gray-100 btn-color-gray-800 rounded-1 flex-column overflow-hidden w-175px h-90px pt-7 pt-lg-5 pb-4 "
            href="#"
          >
            <div className="nav-icon mb-3">
              <i className="ki-outline ki-finance-calculator fs-2x p-0" />
            </div>

            <span className="fw-bold fs-7 lh-1">SKU Costing</span>
          </a>
        </li>

        <li className="nav-item">
          <a
            className="nav-link btn btn-active-primary btn-center bg-gray-100 btn-color-gray-800 rounded-1 flex-column overflow-hidden w-175px h-90px pt-7 pt-lg-5 pb-4  px-3 active"
            href="Catalog-Management.html"
          >
            <div className="nav-icon mb-3">
              <i className="ki-outline ki-abstract-26 fs-2x p-0" />
            </div>

            <span className="fw-bold fs-7 lh-1">Catalog Management</span>
          </a>
        </li>

        <li className="nav-item">
          <a
            className="nav-link btn btn-active-primary btn-center bg-gray-100 btn-color-gray-800 rounded-1 flex-column overflow-hidden w-175px h-90px pt-7 pt-lg-5 pb-4 "
            href="#"
          >
            <div className="nav-icon mb-3">
              <i className="ki-outline ki-questionnaire-tablet fs-2x p-0" />
            </div>

            <span className="fw-bold fs-7 lh-1">Report </span>
          </a>
        </li>

        <li className="nav-item">
          <a
            className="nav-link btn btn-active-primary btn-center bg-gray-100 btn-color-gray-800 rounded-1 flex-column overflow-hidden w-175px h-90px pt-7 pt-lg-5 pb-4 "
            href="channel-list.html"
          >
            <div className="nav-icon mb-3">
              <i className="ki-outline ki-data fs-2x p-0" />
            </div>

            <span className="fw-bold fs-7 lh-1">Channel Integration</span>
          </a>
        </li> */}
      </div>
    </div>
  );
};

export default SidebarEgas;
