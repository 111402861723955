import { styled } from "styled-components";

const Wrapper = styled.div`
  .ant-input-affix-wrapper > .ant-input {
    color: #eaeaea !important;
  }
  .ant_auth_input {
    font-size: 16px !important;
    color: #eaeaea !important;
  }
  .ant_auth_input::placeholder {
    color: #eaeaea !important;
  }
  .ant-input-affix-wrapper > .ant-input::placeholder {
    color: #eaeaea !important;
  }
  .ant-input-suffix > span {
    color: #eaeaea !important;
    font-size: 20px !important;
  }
  .ant-input-outlined {
    background: #292485 !important;
  }
  .ant-input-outlined:hover {
    border-color: #ffffff !important;
    background-color: transparent !important;
  }
  // .ant-input-outlined:focus,
  // .ant-input-outlined:focus-within {
  //   border-color: #ffffff !important;
  //   background-color: transparent !important;
  //   box-shadow: 0px 0px 13px -3px rgb(255 255 255 / 72%) !important;
  // }
`;

export default Wrapper;
