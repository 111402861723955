import Icons from "../../../../components/icons";
import { GetLinks } from "../../../../core";

export default [
  {
    key: "/order-management",
    route_name: "order-management",
    menu_name: "Order",
    icon: <i className="ki-outline ki-basket-ok fs-2x p-0" />,
    label: GetLinks("/order-management", "Order"),
  },
];
