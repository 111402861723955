import { ConfigProvider } from "antd";
import AppRoutes from "./app-routes";
import GlobalCommonContextProvider from "./commonContext";
import { AppStyleWrapper } from "./app-style";
import "./App.css";
import { useEffect } from "react";
import { GlobalDebug } from "./core";
function App() {
  useEffect(() => {
    (process.env.NODE_ENV === "production" ||
      process.env.REACT_APP_ENV === "STAGING") &&
      GlobalDebug(false);
  }, []);
  return (
    <ConfigProvider
      theme={{
        token: {
          // fontFamily: "Poppins, sans-serif",
          colorPrimary: "#443dba",
          colorPrimaryHover: "#0d076a",
        },
      }}
    >
      <GlobalCommonContextProvider>
        <AppStyleWrapper />
        <AppRoutes />
      </GlobalCommonContextProvider>
    </ConfigProvider>
  );
}

export default App;
