import { createGlobalStyle } from "styled-components";
import styled from "styled-components";

// export const AppStyleWrapper = styled.div`
export const AppStyleWrapper = createGlobalStyle`
  .fadeInTop {
    animation-duration: 1s;
    animation-fill-mode: both;
    animation-name: fadeInTop;
    z-index: 99;
  }

  @keyframes fadeInTop {
    from {
      opacity: 0;
      transform: translateY(-25%);
    }
    to {
      opacity: 1;
    }
  }

  .fadeInLeft {
    animation-duration: 1s;
    animation-fill-mode: both;
    animation-iteration-count: infinite;
    animation-name: fadeInLeft;
    animation-timing-function: alternate;
    z-index: 99;
  }

  @keyframes fadeInLeft {
    from {
      opacity: 1;
      transform: translateX(25%);
    }
    to {
      opacity: 1;
    }
  }

  @keyframes lights {
    0% {
      color: hsl(230, 40%, 80%);
      text-shadow: 0 0 1em hsla(320, 100%, 50%, 0.2),
        0 0 0.125em hsla(320, 100%, 60%, 0.3),
        -1em -0.125em 0.5em hsla(40, 100%, 60%, 0),
        1em 0.125em 0.5em hsla(200, 100%, 60%, 0);
    }

    30% {
      color: hsl(230, 80%, 90%);
      text-shadow: 0 0 1em hsla(320, 100%, 50%, 0.5),
        0 0 0.125em hsla(320, 100%, 60%, 0.5),
        -0.5em -0.125em 0.25em hsla(40, 100%, 60%, 0.2),
        0.5em 0.125em 0.25em hsla(200, 100%, 60%, 0.4);
    }

    40% {
      color: hsl(230, 100%, 95%);
      text-shadow: 0 0 1em hsla(320, 100%, 50%, 0.5),
        0 0 0.125em hsla(320, 100%, 90%, 0.5),
        -0.25em -0.125em 0.125em hsla(40, 100%, 60%, 0.2),
        0.25em 0.125em 0.125em hsla(200, 100%, 60%, 0.4);
    }

    70% {
      color: hsl(230, 80%, 90%);
      text-shadow: 0 0 1em hsla(320, 100%, 50%, 0.5),
        0 0 0.125em hsla(320, 100%, 60%, 0.5),
        0.5em -0.125em 0.25em hsla(40, 100%, 60%, 0.2),
        -0.5em 0.125em 0.25em hsla(200, 100%, 60%, 0.4);
    }

    100% {
      color: hsl(230, 40%, 80%);
      text-shadow: 0 0 1em hsla(320, 100%, 50%, 0.2),
        0 0 0.125em hsla(320, 100%, 60%, 0.3),
        1em -0.125em 0.5em hsla(40, 100%, 60%, 0),
        -1em 0.125em 0.5em hsla(200, 100%, 60%, 0);
    }
  }

  .mainHeaderTextAnimation {
    // font-style: italic;
    // text-transform: uppercase;
    color: transparent;
    // -webkit-text-stroke: #fff;
    // -webkit-text-stroke-width: 1px;
    text-shadow: 2px 2px 10px $blue;
    transition: all 0.5s ease-in-out;
    text-align: center;
    // letter-spacing: 0.2em;
    animation: flicker 0.5s ease-in-out infinite alternate;
    &:hover {
      color: #443dba !important;
    }
  }

  @keyframes flicker {
    0% {
      opacity: 0.5;
      text-shadow: 2px 2px 10px $blue;
    }
    100% {
      opacity: 1;
      text-shadow: 2px 2px 20px $blue;
    }
  }
  .ant_common_input {
    font-size: 15px !important;
    color: #4c4c4c !important;
  }
  .ant_common_input::placeholder {
    color: #cccccc !important;
  }
  .ant-input-lg {
    line-height: 1.6 !important;
  }
  .ant-select .ant-select-selector {
    font-size: 15px !important;
  }
  .ant-input-outlined:hover {
    border-color: #443dba !important;
    // background-color: #ffffff !important;
  }
  .ant_search_input {
    font-size: 15px !important;
    // background-color : #f9f9f9 !important;
    color: #4c4c4c !important;
    // border-color : #8e8ad2 !important;
    padding: 7px 11px !important;
  }
  .ant_search_input .ant-input-prefix i {
    //  color:#443dba !important;
    font-size: 20px !important;
  }

  // .ant_search_input {
  //   font-size: 15px !important;
  //   background-color : #f9f9f9 !important;
  //   color: #4c4c4c !important;
  //   border-color : #8e8ad2 !important;
  //   padding :6px 11px !important;
  // }
  // .ant_search_input .ant-input-prefix i{
  //  color:#443dba !important;
  //  font-size:20px !important;
  // }

  .headerUserProfileClickDiv {
    background: #f2f1fa;
    border-radius: 10px;
    margin: 0px 11px;
    color: #443dba;
  }

  // .ant-upload-list {
  //   display: none !important;
  // }

  .cus-check img {
    border: 1px solid #ededed !important;
    border-radius: 10px !important;
    width: 100px !important;
    height: 100px !important;
    object-fit: contain !important;
  }

  .ant-table-wrapper .ant-table-thead > tr > th,
  .ant-table-wrapper .ant-table-thead > tr > td {
    background: #f3f3ff !important;
    color: #1e2129 !important;
    font-weight: 700 !important;
  }

  .ant-form-item .ant-form-item-label > label {
    font-size: 14px !important;
    font-weight: 500 !important;
  }

  .tracking-in-expand {
    -webkit-animation: tracking-in-expand 2.7s
      cubic-bezier(0.215, 0.61, 0.355, 1) both;
    animation: tracking-in-expand 0.7s cubic-bezier(0.215, 0.61, 0.355, 1) infinite both;
  }

  @-webkit-keyframes tracking-in-expand {
    0% {
      letter-spacing: -0.5em;
      opacity: 0;
    }
    40% {
      opacity: 0.6;
    }
    100% {
      opacity: 1;
    }
  }
  @keyframes tracking-in-expand {
    0% {
      letter-spacing: -0.5em;
      opacity: 0;
    }
    40% {
      opacity: 0.6;
    }
    100% {
      opacity: 1;
    }
  }

  .menu-item .menu-link {
    color: var(--bs-gray-800);
    font-weight: 500 !important;
    padding: 0.45rem 0.95rem !important; 
  }


  // to change color of antd-notification
  // .ant-notification
  //   .ant-notification-notice-wrapper
  //   .ant-notification-notice-message {
  //   margin-bottom: 8px;
  //   color: red !important;
  //   font-size: 16px;
  //   line-height: 1.5;
  // }


  @media screen and (max-width: 767px) {
    .ant-picker-dropdown .ant-picker-panel-container .ant-picker-panels {
      display: inline-flex;
      flex-direction: column;
      flex-wrap: nowrap;
      direction: ltr;
    }

    .ant-picker-dropdown .ant-picker-panel-container .ant-picker-panel-layout {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      flex-wrap: nowrap;
      align-items: stretch;
    }

    .ant-picker-dropdown .ant-picker-panel-container .ant-picker-presets {
      min-height: 300px;
    }
  }

  input:-webkit-autofill {
    background-color: #fff !important; /* Change background color */
    color: #fff !important; /* Change text color */
    box-shadow: 0 0 0 30px #292485 inset !important; /* Create a solid background */
    -webkit-text-fill-color: #fff !important; /* Ensure text color is applied */
  }

  .ant-input-affix-wrapper >input.ant-input:focus {
    box-shadow: 0 0 0 30px #292485 inset !important; /* Create a solid background */
  }




`;
