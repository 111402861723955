import { HomeOutlined, UserOutlined } from "@ant-design/icons";
import {
  Breadcrumb,
  Dropdown,
  Layout,
  Select,
  Tooltip,
  message,
  notification,
  theme,
} from "antd";
import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { useLocation, useHistory } from "react-router-dom";

import { Wrapper } from "./style";
import { GlobalContext } from "../../../commonContext";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { DisplayNotification, SetUserInformation } from "../../../config";
const { useToken } = theme;
const { Header, Content, Footer, Sider } = Layout;

const HeaderEgas = (props) => {
  const { setCollapsed, collapsed } = props;
  const contextValue = useContext(GlobalContext);

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const history = useHistory();
  const [companyList, setCompanyList] = useState([]);
  const [companyId, setCompanyId] = useState(null);

  const userType = localStorage.getItem("user_type");

  // const company_id_c = useMemo(() => {
  //   try {
  //     return String(contextValue?.data?.company_id);
  //   } catch {
  //     return null;
  //   }
  // }, [contextValue?.data?.company_id]);

  const company_id_c = useMemo(() => {
    return contextValue?.data?.company_id !== undefined &&
      contextValue?.data?.company_id !== "undefined"
      ? String(contextValue?.data.company_id)
      : undefined;
  }, [contextValue?.data?.company_id]);

  const company_list = useMemo(() => {
    try {
      return JSON.parse(contextValue?.data?.company_list);
    } catch {
      return null;
    }
  }, [contextValue?.data?.company_list]);

  useEffect(() => {
    if (userType === "2") {
      const options = company_list?.map((c) => {
        return {
          value: String(c.id),
          label: c.company_name,
        };
      });

      console.log(company_id_c, typeof company_id_c, "company_id_c");

      console.log(options, "options");

      // if (company_id_c) {
      //   setCompanyId(company_id_c);
      // }

      setCompanyList(options);
    }
  }, [userType, company_id_c, company_list]);

  const {
    token: { colorBgContainer },
  } = theme.useToken();
  const token = useToken();
  const contentStyle = {
    backgroundColor: token.colorBgElevated,
    borderRadius: token.borderRadiusLG,
    boxShadow: token.boxShadowSecondary,
    minWidth: "150px",
  };

  const items = [
    {
      label: (
        <div className="menu-item px-3 ">
          <div className="menu-content d-flex align-items-center px-3">
            <div className="symbol symbol-50px me-5">
              <img alt="Logo" src="/assets/media/blank.png" />
            </div>

            <div className="d-flex flex-column">
              <div className="fw-bold d-flex align-items-center fs-5">
                {JSON.parse(contextValue?.data?.user_data)?.name}
              </div>
              <a
                href="#"
                className="fw-semibold text-muted text-hover-primary fs-7"
              >
                {JSON.parse(contextValue?.data?.user_data)?.email}
              </a>
            </div>
          </div>
        </div>
      ),
      key: "0",
    },
    {
      label: (
        <>
          <div className="separator " />
          {localStorage.getItem("user_type") === "2" && (
            <div className="menu-item">
              <Link to={{ pathname: "/manage-company" }}>
                <span className="menu-link px-5">Manage Company</span>
              </Link>
            </div>
          )}
        </>
      ),
      key: "1",
    },
    {
      label: (
        <>
          <div className="menu-item ">
            <Link to={{ pathname: "/application-log" }}>
              <span className="menu-link px-5">Application Log</span>
            </Link>
          </div>
        </>
      ),
      key: "2",
    },
    {
      label: (
        <>
          <div className="menu-item ">
            <span className="menu-link px-5">My Profile</span>
          </div>
        </>
      ),
      key: "3",
    },
    {
      label: (
        <>
          <div className="separator " />

          <div className="menu-item ">
            <span className="menu-link px-5">Account Settings</span>
          </div>
        </>
      ),
      key: "4",
    },
    {
      label: (
        <>
          <div className="menu-item ">
            <span
              onClick={() => {
                localStorage.clear();
                history.push("/login");
              }}
              className="menu-link bg-primary text-light justify-content-center rounded px-5"
            >
              Sign Out
            </span>
          </div>
        </>
      ),
      key: "5",
    },

    // {
    //   type: "divider",
    // },
    // {
    //   label: "3rd menu item",
    //   key: "3",
    // },
  ];

  const handleCompanyChange = (selectedCompanyId) => {
    let updates = { company_id: selectedCompanyId };
    localStorage.setItem("company_id", selectedCompanyId);

    if (selectedCompanyId) {
      // IF THERE IS A COMPANY SELECTED THAT IT SET PLATEFORMS AND SELECTED PLATFORM FROM THE COMPANY DATA
      const selectedCompany = company_list?.find(
        (company) => String(company.id) === String(selectedCompanyId)
      );
      if (selectedCompany) {
        updates = {
          ...updates,
          platforms: selectedCompany?.platforms || "[]",
          selected_platform: selectedCompany?.selected_platform || null,
        };

        DisplayNotification(
          "success",
          "Success",
          `Switched to ${selectedCompany.company_name}`
        );
      }
    } else {
      // IF THERE IS NOT COMPANY SELECTED THAT IT SET PLATEFORMS AND SELECTED PLATFORM FROM THE MAIN USERS DATA
      const userData = JSON.parse(localStorage.getItem("user_data") || "{}");

      updates = {
        ...updates,
        platforms: JSON.stringify(userData?.platforms) || "[]",
        selected_platform: userData?.selected_platform || null,
      };
    }

    Object.entries(updates).forEach(([key, value]) => {
      localStorage.setItem(key, value);
    });

    contextValue?.updateCommonGlobalVal(updates);
    setCompanyId(selectedCompanyId);
  };

  return (
    <div
      id="kt_app_header"
      className="app-header "
      data-kt-sticky="true"
      data-kt-sticky-activate="{default: false, lg: true}"
      data-kt-sticky-name="app-header-sticky"
      data-kt-sticky-offset="{default: false, lg: '300px'}"
    >
      <div
        className="app-container container-fluid d-flex align-items-stretch justify-content-between"
        id="kt_app_header_container"
      >
        <div
          className="d-flex align-items-center justify-content-between flex-row-fluid"
          id="kt_app_header_wrapper"
        >
          <div className="app-header-logo d-flex align-items-center">
            {/* <div
              className="btn btn-icon btn-color-gray-500 btn-active-color-primary w-35px h-35px ms-n3 me-2 d-flex d-lg-none"
              id="kt_app_sidebar_mobile_toggle"
            >
              <i className="ki-outline ki-abstract-14 fs-1" />
            </div> */}

            <span className="me-5 me-lg-9">
              <img
                alt="Logo"
                src="/assets/media/egas-icon.png"
                className="h-25px h-sm-50px"
              />
            </span>

            <div className="d-none d-md-inline">
              <div className="fw-semibold fs-3 text-gray-900 ">
                Welcome Back,
                <span className="fw-bolder text-primary ">
                  {" "}
                  {JSON.parse(contextValue?.data?.user_data)?.name}
                </span>
              </div>
              {/* <div class="fw-semibold text-gray-500">Plan your blog post by choosing a topic</div> */}
            </div>
          </div>

          <div className="app-navbar flex-shrink-0">
            <div className="app-navbar-item me-3 w-140px">
              {/* <form
                data-kt-search-element="form"
                className="d-none d-lg-block w-100 position-relative mb-5 mb-lg-0"
                autoComplete="off"
              >
                <i className="ki-outline ki-magnifier search-icon fs-2 text-gray-500 position-absolute top-50 translate-middle-y ms-5" />{" "}
                <input
                  type="text"
                  className="search-input form-control border-dashed border-gray-300 ps-13"
                  name="search"
                  placeholder="Search..."
                  data-kt-search-element="input"
                />
              </form> */}

              {localStorage.getItem("user_type") === "2" && (
                <Select
                  size="large"
                  placeholder="Select Company"
                  variant="filled"
                  allowClear={true}
                  value={company_id_c || null}
                  options={companyList}
                  className="w-100px w-md-200px"
                  onChange={handleCompanyChange}
                  // onClear={() => {
                  //   localStorage.removeItem("company_id");
                  // }}
                  onClear={() => handleCompanyChange(null)}
                />
              )}
              {/* <Select
                // className="w-140px"
                size="large"
                placeholder="Select Company"
                // value={filters?.company}
                options={companyList?.map((company) => ({
                  value: company.id,
                  label: company.name,
                }))}
                style={{ width: 250 }}
                // onChange={(e) => {
                //   filters.company = e;
                // }}
                // loading={platformLoading}
                // disabled={platformLoading}
              /> */}
            </div>

            {/* <div className="app-navbar-item me-3">
              <a
                href="#"
                className="d-flex align-items-center"
                data-kt-menu-trigger="{default: 'click', lg: 'hover'}"
                data-kt-menu-attach="parent"
                data-kt-menu-placement="bottom-end"
              >
                <button
                  className="btn btn-icon btn-body position-relative btn btn-icon btn-icon-gray-600 border border-dashed border-gray-300 w-35px h-35px w-md-40px h-md-40px"
                  id="kt_drawer_chat_toggle"
                >
                  <i className="ki-outline ki-messages fs-3" />
                  <span className="position-absolute top-0 start-100 translate-middle badge badge-circle badge-sm badge-info">
                    5
                  </span>
                </button>
              </a>
            </div> */}

            {/* <div className="app-navbar-item me-3">
              <div
                className="btn btn-icon btn-icon-gray-600 border border-dashed border-gray-300 w-35px h-35px w-md-40px h-md-40px"
                data-kt-menu-trigger="{default: 'click', lg: 'hover'}"
                data-kt-menu-attach="parent"
                data-kt-menu-placement="bottom-end"
              >
                <i className="ki-outline ki-element-11 fs-3" />
              </div>
            </div> */}

            <div
              className="app-navbar-item ms-1 ms-md-3"
              id="kt_header_user_menu_toggle"
            >
              <Dropdown
                trigger={["click"]}
                placement="bottomRight"
                size="small"
                menu={{
                  items,
                }}
                // dropdownRender={(menu) => (
                //   <div style={contentStyle}>
                //     <div>
                //       <div className="separator border-gray-200" />
                //       <div
                //         style={{
                //           minHeight: "230px",
                //           minWidth: "150px",
                //           border: "1px solid #fff",
                //           borderRadius: "7px",
                //           overflow: "auto",
                //           background: "#fff",
                //           boxShadow: "#cccccc 0px 0px 12px 2px",
                //         }}
                //       >
                //         <div
                //           className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg menu-state-color fw-semibold py-4 fs-6 w-275px"
                //           data-kt-menu="true"
                //           style={{ display: "block" }}
                //         >
                //           {React.cloneElement(menu, {})}
                //           <div className="menu-item px-3 ">
                //             <div className="menu-content d-flex align-items-center px-3">
                //               <div className="symbol symbol-50px me-5">
                //                 <img alt="Logo" src="/assets/media/blank.png" />
                //               </div>

                //               <div className="d-flex flex-column">
                //                 <div className="fw-bold d-flex align-items-center fs-5">
                //                   {
                //                     JSON.parse(contextValue?.data?.user_data)
                //                       ?.name
                //                   }
                //                 </div>
                //                 <a
                //                   href="#"
                //                   className="fw-semibold text-muted text-hover-primary fs-7"
                //                 >
                //                   {
                //                     JSON.parse(contextValue?.data?.user_data)
                //                       ?.email
                //                   }
                //                 </a>
                //               </div>
                //             </div>
                //           </div>

                //           <div className="separator my-2" />

                //           {localStorage.getItem("user_type") === "2" && (
                //             <div className="menu-item px-5">
                //               <Link to={{ pathname: "/manage-company" }}>
                //                 <span className="menu-link px-5">
                //                   Manage Company
                //                 </span>
                //               </Link>
                //             </div>
                //           )}

                //           <div className="menu-item px-5">
                //             <Link to={{ pathname: "/application-log" }}>
                //               <span className="menu-link px-5">
                //                 Application Log
                //               </span>
                //             </Link>
                //           </div>
                //           <div className="menu-item px-5 my-1">
                //             <span className="menu-link px-5">My Profile</span>
                //           </div>
                //           <div className="separator my-2" />

                //           <div className="menu-item px-5 my-1">
                //             <span className="menu-link px-5">
                //               Account Settings
                //             </span>
                //           </div>

                //           <div className="separator my-2" />

                //           <div className="menu-item px-5">
                //             <span
                //               onClick={() => {
                //                 localStorage.clear();
                //                 history.push("/login");
                //               }}
                //               className="menu-link px-5 bg-primary text-light justify-content-center"
                //             >
                //               Sign Out
                //             </span>
                //           </div>
                //         </div>
                //       </div>
                //     </div>
                //   </div>
                // )}
              >
                <div
                  onClick={(e) => e.preventDefault()}
                  className="d-flex cursor-pointer "
                >
                  <div className="symbol symbol-circle symbol-30px symbol-md-40px ">
                    <img src="/assets/media/blank.png" alt="user" />
                  </div>
                  <div class="d-none d-md-flex flex-column ms-4">
                    <div class="fw-bold d-flex align-items-center fs-5">
                      {JSON.parse(contextValue?.data?.user_data)?.name}
                    </div>
                    <div class="fw-semibold text-muted text-hover-primary fs-7">
                      {JSON.parse(contextValue?.data?.user_data)?.email}
                    </div>
                  </div>
                </div>
              </Dropdown>
            </div>

            <div className="app-navbar-item ms-5">
              {" "}
              {localStorage.getItem("isAdmin") == 1 && (
                <span
                  onClick={() => {
                    const admin_data = localStorage.getItem("admin_data");
                    const data = JSON.parse(admin_data);
                    const response = JSON.parse(data?.user_data);

                    localStorage.clear();

                    const newRes = {
                      response_data: {
                        auth_token: data?.token,
                        user_data: JSON.parse(data?.user_data),
                      },
                    };
                    const oldRes = {
                      auth_token: data?.token,
                      user_data: JSON.parse(data?.user_data),
                    };
                    SetUserInformation(contextValue, newRes, "login");
                    history.replace("/manage-user");
                  }}
                  className="btn btn-flex btn-icon align-self-center fw-bold btn-primary w-35px w-md-100 h-35px h-md-40px px-4 fs-7"
                >
                  <i className="ki-outline ki-arrow-left fs-3 fadeInLeft" />
                  <span className="d-none d-md-inline ms-2">Back to Admin</span>
                </span>
              )}
            </div>
            <div
              className="app-navbar-item ms-3"
              onClick={() => {
                localStorage.clear();
                history.push("/login");
              }}
            >
              <Tooltip title="Logout">
                <div className="btn btn-icon btn-icon-gray-600 border border-dashed border-danger w-35px h-35px w-md-40px h-md-40px">
                  <i className="fa-solid fa-power-off fs-3 text-danger" />
                </div>
              </Tooltip>
            </div>

            {/*end::Action*/}
            {/*begin::Sidebar menu toggle*/}
            {/* <div className="app-navbar-item d-flex align-items-center d-lg-none ms-1 me-n3">
                <a
                  href="#"
                  className="btn btn-icon btn-color-gray-500 btn-active-color-primary w-35px h-35px"
                  id="kt_app_header_menu_toggle"
                >
                  <i className="ki-outline ki-text-align-left fs-1" />
                </a>
              </div> */}

            {/* <div className="app-navbar-item d-flex align-items-center d-lg-none ms-1 me-n3">
              <a
                href="#"
                className="btn btn-icon btn-color-gray-500 btn-active-color-primary w-35px h-35px"
                id="kt_app_header_menu_toggle"
              >
                <i className="ki-outline ki-text-align-left fs-1" />
              </a>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeaderEgas;
