import { lazy } from "react";

export default [
  {
    path: "/dashboard",
    component: lazy(() => import("../modules/pages/dashboard/index")),
    exact: true,
  },
  {
    path: "/",
    component: lazy(() => import("../modules/pages/dashboard/index")),
    exact: true,
  },
];
