import { useContext } from "react";
import Icons from "../../../../components/icons";
import { GetLinks } from "../../../../core";

export default [
  {
    key: "/catalog-management",
    route_name: "catalog-management",
    menu_name: "Catalog Management",
    icon: <i className="ki-outline ki-abstract-26 fs-2x p-0" />,
    label: GetLinks("/catalog-management", "Catalog Management"),
  },
];
