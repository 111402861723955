import React, { Suspense } from "react";
import { Route, Switch } from "react-router-dom";
import Error from "../components/error";
import catelogManagement from "./catelog-management";
import manageUser from "./manage-user";
import setting from "./setting";
import applicationLog from "./application-log";
import masterCatalog from "./master-catalog";
import addMasterCatalog from "./add-master-catalog";
import order from "./order";
import viewOrder from "./view-order";
import dashboard from "./dashboard";
import channelIntegration from "./channel-integration";
import report from "./report";
import manageCompany from "./manage-company";
// export const Routes_ =
//   localStorage.getItem("user_type") == 1 ||
//     ? [...manageUser]
//     : [...manageUser, ...catelogManagement, ...setting];

// export const Routes_ = [...manageUser, ...catelogManagement, ...setting];
const PageRouter = () => {
  const Routes_ =
    localStorage.getItem("user_type") == 1
      ? [...manageUser]
      : [
          ...channelIntegration,
          ...dashboard,
          ...applicationLog,
          ...catelogManagement,
          ...setting,
          ...masterCatalog,
          ...addMasterCatalog,
          ...order,
          ...viewOrder,
          ...report,
          ...manageCompany,
        ];

  return (
    <Suspense>
      <Switch>
        {Routes_?.map((d, i) => {
          return (
            <Route
              key={i}
              path={d?.path}
              component={d?.component}
              exact={d?.exact}
            />
          );
        })}

        {/* <Route path="*" render={() => <div>Error Page</div>} /> */}
        <Route path="*" render={() => <Error />} />
      </Switch>
    </Suspense>
  );
};

export default PageRouter;
