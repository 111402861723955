import { lazy } from "react";

export default [
  {
    path: "/report",
    component: lazy(() => import("../modules/pages/report/index")),
    exact: true,
  },
  {
    path: "/report/sales-gstr",
    component: lazy(() => import("../modules/pages/report/index")),
    exact: true,
  },
  {
    path: "/report/payment-reconciliation",
    component: lazy(() => import("../modules/pages/report/index")),
    exact: true,
  },
  {
    path: "/report/inventory",
    component: lazy(() => import("../modules/pages/report/index")),
    exact: true,
  },
  {
    path: "/report/order",
    component: lazy(() => import("../modules/pages/report/index")),
    exact: true,
  },
  {
    path: "/report/return",
    component: lazy(() => import("../modules/pages/report/index")),
    exact: true,
  },
];
