import manageUser from "./manage-user";
import setting from "./setting";
import catalogManagement from "./catalog-management";
import businessReport from "./business-report";
import dashboard from "./dashboard";
import order from "./order";
import skuCosting from "./sku-costing";
import report from "./report";
import channelIntegration from "./channel-integration";
// const payload = [...amazonFeeBreakdown, ...haloSales, ...manageUser];
const payload = {
  users: [
    ...businessReport,
    ...dashboard,
    ...order,
    ...catalogManagement,
    ...skuCosting,
    ...report,
    ...channelIntegration,
  ],
  // comman: [
  //   // ...dashboard,
  //   ...threepSalesOverview,
  //   ...realTimeSales,
  //   // ...asinSearchQueryPerformance,
  //   // ...walmartSales,
  //   // ...subscription,
  //   ...resources,
  //   ...setting,
  // ],
  admin: [...manageUser],
};

export default payload;
