import React from "react";
import { Button, Layout, Menu, theme } from "antd";
const { Header, Content, Footer, Sider } = Layout;

const FooterEgas = () => {
  return (
    <div
      id="kt_app_footer"
      className="app-footer d-flex flex-column flex-md-row flex-center flex-md-stack py-3"
    >
      {/*begin::Copyright*/}
      <div className="text-dark order-2 order-md-1">
        <span className="fw-semibold me-.0">Copyright ©</span>
        <a
          href
          target="_blank"
          className="text-primary fw-bolder text-hover-primary"
        >
          eGas Technology 2024.
        </a>
        <span className="fw-semibold me-1">All rights reserved.</span>
      </div>
      {/*end::Copyright*/}
      {/*begin::Menu*/}
      <ul className="menu menu-gray-600 menu-hover-primary fw-semibold order-1">
        <li className="menu-item">
          {/* <a href target="_blank" className="menu-link px-2">
            About us
          </a> */}
        </li>
        <li className="menu-item">
          {/* <a href target="_blank" className="menu-link px-2">
            Terms &amp; Conditions
          </a> */}
        </li>
      </ul>
      {/*end::Menu*/}
    </div>
  );
};

export default FooterEgas;
