import React, { Suspense } from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import Page from "./modules/pages";
import Login from "./modules/pages/auth";
import SignUp from "./modules/pages/auth/lib/sign-up";

function AppRouter() {
  return (
    // basename="/egas"
    <BrowserRouter>
      <Suspense>
        <Switch>
          <Route path="/login" component={Login} exact />
          <Route path="/sign-up" component={SignUp} exact />
          <Route path="/" component={Page} />
          <Redirect to="/" />
        </Switch>
      </Suspense>
    </BrowserRouter>
  );
}

export default AppRouter;
