import { lazy } from "react";

export default [
  {
    path: "/order-management/view-order",
    component: lazy(() =>
      import(
        "../modules/pages/order-management/amazon-order-management/lib/view-order"
      )
    ),
    exact: true,
  },
];
