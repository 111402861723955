import Icons from "../../../../components/icons";
import { GetLinks } from "../../../../core";

export default [
  {
    key: "/sku-costing",
    route_name: "sku-costing",
    menu_name: "SKU Costing",
    icon: <i className="ki-outline ki-finance-calculator fs-2x p-0" />,
    label: GetLinks("/sku-costing", "SKU Costing"),
  },
];
