import { lazy } from "react";

export default [
  {
    path: "/callbackads",
    component: lazy(() => import("../modules/pages/setting")),
    exact: true,
  },
  {
    path: "/spapi-callback",
    component: lazy(() => import("../modules/pages/setting")),
    exact: true,
  },
  {
    path: "/settings",
    component: lazy(() => import("../modules/pages/setting")),
    exact: true,
  },
  {
    path: "/reset-password",
    component: lazy(() => import("../modules/pages/setting")),
    exact: true,
  },
  {
    path: "/seller-profile",
    component: lazy(() => import("../modules/pages/setting")),
    exact: true,
  },
];
