import Icons from "../../../../components/icons";
import { GetLinks } from "../../../../core";

export default [
  {
    key: "/business-report",
    route_name: "business-report",
    menu_name: "Business Report",
    icon: <i className="ki-outline ki-chart-line-star fs-2x p-0" />,
    label: GetLinks("/business-report", "Business Report"),
  },
];
