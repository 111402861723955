import { useContext, useEffect, useState } from "react";
import { Redirect } from "react-router-dom";
import Wrapper from "./style";
import { Button, Form, Input, message } from "antd";
import { GlobalContext } from "../../../commonContext";
import { MakeCommonApiCall } from "../../../apis/common_apis";
import { DisplayNotification, SetUserInformation } from "../../../config";
import { Link, useHistory } from "react-router-dom";

function Login() {
  const history = useHistory();
  const contextValue = useContext(GlobalContext);
  const [redirect, setRedirect] = useState(false);
  const [loading, setLoading] = useState(false);
  const [userInfo, setUserInfo] = useState({
    username: null,
    password: null,
  });
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);

    window.addEventListener("resize", handleResize);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const fetchCompanyList = async () => {
    try {
      const companyListResponse = await MakeCommonApiCall(
        `company/list`,
        `get`,
        null,
        true
      );
      return companyListResponse?.data || [];
    } catch (error) {
      console.error("Error fetching company list:", error);
      return []; // Return an empty array in case of error
    }
  };

  const submit = async () => {
    setLoading(true);
    try {
      const response = await MakeCommonApiCall("login", "post", {
        ...userInfo,
      });

      if (response?.status) {
        const userType = response?.data?.user_data?.user_type;

        if (userType === "2" || userType === 2) {
          // Set token
          const token = response?.data?.auth_token;
          if (token) {
            localStorage.setItem("token", token);

            // Fetch company list using the separate function
            const companyList = await fetchCompanyList();

            const data = {
              response_data: response?.data,
              company_list: companyList,
            };

            message.destroy();
            setTimeout(() => {
              SetUserInformation(contextValue, data, "login");
              setLoading(false);
              history.replace("/catalog-management");
            }, 1000);
          } else {
            throw new Error("Token not received from login response");
          }
        } else {
          // For other user types, proceed as before
          const data = {
            response_data: response?.data,
          };
          SetUserInformation(contextValue, data, "login");
          setLoading(false);
          setRedirect(true);
        }
      } else {
        throw new Error(response?.message || "Login failed");
      }
    } catch (error) {
      setLoading(false);
      DisplayNotification("error", "Error", error.message);
    }
  };

  if (localStorage.getItem("token") || redirect) {
    return (
      <Redirect
        to={
          localStorage.getItem("user_type") == 1
            ? `/manage-user`
            : "/catalog-management"
        }
      />
    );
  }

  return (
    <div
      className="app-blank bgi-size-cover bgi-attachment-fixed bgi-position-center bgi-no-repeat"
      style={{
        height: windowWidth > 992 ? "100%" : "auto",
        backgroundImage: "url(/assets/media/3303325.jpg)",
        backgroundSize: "cover",
      }}
    >
      <div
        className="d-flex flex-column flex-column-fluid flex-lg-row"
        style={{ height: "100%" }}
      >
        {/*begin::Aside*/}
        <div className="d-flex flex-center w-lg-50 pt-15 pt-lg-0 px-10">
          {/*begin::Aside*/}
          <div className="d-flex flex-center flex-lg-start flex-column">
            {/*begin::Logo*/}
            <div className="mb-7">
              <img
                alt="Logo"
                src="/assets/media/egas-logo-white.png"
                className="w-100"
              />
            </div>
            {/*end::Logo*/}
            {/*begin::Title*/}
            <h2 className="text-white fw-normal m-0 ms-9">
              Branding tools designed for your business
            </h2>
            {/*end::Title*/}
          </div>
          {/*begin::Aside*/}
        </div>
        {/*begin::Aside*/}
        {/*begin::Body*/}
        <div className="d-flex flex-column-fluid flex-lg-row-auto justify-content-center justify-content-lg-end p-12 p-lg-40">
          {/*begin::Card*/}
          <div
            className="d-flex flex-column align-items-stretch flex-center rounded-4 w-md-600px p-20"
            style={{
              backgroundColor: "#0000002e",
              backdropFilter: "blur(15px)",
              border: "2px solid #ffffff26",
            }}
          >
            {/*begin::Wrapper*/}
            <div className="d-flex flex-center flex-column flex-column-fluid px-lg-10 pb-15 pb-lg-10">
              {/*begin::Form*/}
              <div className="w-100">
                {/*begin::Heading*/}
                <Wrapper>
                  <div className="text-center mb-18">
                    {/*begin::Title*/}
                    <h1 className="text-gray-100 fw-bold fs-2x mb-3">
                      🙌 Welcome in eGas Platform
                    </h1>
                    {/*end::Title*/}
                    {/*begin::Subtitle*/}
                    <div className="text-gray-400 fw-semibold fs-6">
                      Please enter your account details.
                    </div>
                    {/*end::Subtitle=*/}
                  </div>
                  {/*begin::Heading*/}
                  {/*begin::Input group=*/}
                  <div className="fv-row mb-8">
                    {/*begin::Email*/}
                    <Input
                      type="text"
                      placeholder="Email"
                      name="username"
                      autoComplete="new-email"
                      className="ant_auth_input  h-60px"
                      value={userInfo?.username}
                      onChange={(e) => {
                        setUserInfo({ ...userInfo, username: e.target.value });
                      }}
                    />
                    {/*end::Email*/}
                  </div>
                  {/*end::Input group=*/}
                  <div className="fv-row mb-3 position-relative">
                    {/*begin::Password*/}
                    <Input.Password
                      placeholder="Password"
                      name="password"
                      autoComplete="new-password"
                      className="ant_auth_input  h-60px"
                      value={userInfo?.password}
                      onChange={(e) => {
                        setUserInfo({ ...userInfo, password: e.target.value });
                      }}
                    />

                    {/*end::Password*/}
                  </div>
                </Wrapper>

                {/*end::Input group=*/}
                {/*begin::Wrapper*/}
                <div className="d-flex flex-center mt-5 flex-wrap gap-3 fs-base fw-semibold mb-8">
                  {/* <div>sd</div> */}
                  {/*begin::Link*/}
                  <a href="" className="text-light">
                    Forgot Password ?
                  </a>
                  {/*end::Link*/}
                </div>
                {/*end::Wrapper*/}
                {/*begin::Submit button*/}
                <div className="d-grid mb-10">
                  <button
                    onClick={() => {
                      if (userInfo?.username && userInfo?.password) {
                        submit();
                      } else {
                        message.destroy();
                        message.error("Invalid Email or Password");
                      }
                    }}
                    disabled={loading}
                    className="btn btn-primary h-50px"
                  >
                    {/*begin::Indicator label*/}

                    {/*end::Indicator label*/}
                    {/*begin::Indicator progress*/}
                    {loading ? (
                      <span
                        className="indicator-progress"
                        style={{ display: "block" }}
                      >
                        Please wait...
                        <span className="spinner-border spinner-border-sm align-middle ms-2" />
                      </span>
                    ) : (
                      <span className="indicator-label">Sign In</span>
                    )}

                    {/*end::Indicator progress*/}
                  </button>
                </div>
                {/*end::Submit button*/}
              </div>
              {/*end::Form*/}
            </div>
            {/*end::Wrapper*/}
            {/*begin::Footer*/}
            <div className="d-flex flex-stack px-lg-10 justify-content-center ">
              {/*begin::Sign up*/}
              <div className="text-gray-500 text-center fw-semibold fs-6">
                Not a Member yet?
                <Link
                  to="/sign-up"
                  className=" text-white fw-bolder ms-2"
                  style={{ color: "#007c00" }}
                >
                  Sign up
                </Link>
              </div>
              {/*end::Sign up*/}
            </div>
            {/*end::Footer*/}
          </div>
          {/*end::Card*/}
        </div>
        {/*end::Body*/}
      </div>
    </div>
  );
}
export default Login;
