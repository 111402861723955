import { useContext, useEffect, useState } from "react";
import { Redirect } from "react-router-dom";
import Wrapper from "../style";
import { Button, Checkbox, Form, Input, message } from "antd";
import { GlobalContext } from "../../../../commonContext";
import { MakeCommonApiCall } from "../../../../apis/common_apis";
import { DisplayNotification, SetUserInformation } from "../../../../config";
import { Link } from "react-router-dom";

function SignUp() {
  const [sending, setSending] = useState(false);
  const [countdown, setCountdown] = useState(0);
  const [redirect, setRedirect] = useState(false);
  const [password, set_password] = useState("");
  const [confirm_pass, set_confirm_pass] = useState("");
  const [userData, setUserData] = useState({
    name: "",
    email: "",
    contact_no: "",
    address: "",
    platforms: [],
  });
  const [otpState, setOtpState] = useState({
    sendOtpLoading: false,
    verifyOtpLoading: false,
    getOtp: false,
    otp: "",
    otpVerifiedFailed: false,
  });

  const allPlatform = [
    {
      label: (
        <div className="text-white">
          <img
            src="/assets/media/platform_image/Amazon.png"
            style={{ width: "20px" }}
            alt=""
            className="me-2"
          />
          Amazon
        </div>
      ),
      value: "Amazon",
    },
    {
      label: (
        <div className="text-white">
          <img
            src="/assets/media/platform_image/Flipkart.png"
            style={{ width: "20px" }}
            alt=""
            className="me-2"
          />
          Flipkart
        </div>
      ),
      value: "Flipkart",
    },
    {
      label: (
        <div className="text-white">
          <img
            src="/assets/media/platform_image/Myntra.png"
            style={{ width: "20px" }}
            alt=""
            className="me-2"
          />
          Myntra
        </div>
      ),
      value: "Myntra",
    },
  ];

  const onSubmitUser = async () => {
    // message.destroy();
    // message.loading("Loading", 0);

    // Check if email exists
    const checkEmailBody = {
      email: userData.email,
    };

    try {
      const checkEmailResponse = await MakeCommonApiCall(
        "user/check-email",
        "post",
        checkEmailBody,
        true
      );
      if (checkEmailResponse?.status && checkEmailResponse?.data?.is_email) {
        // Email exists, proceed with signup
        const body = {
          ...userData,
          selected_platform: userData?.platforms?.[0],
          password: password,
        };

        const response = await MakeCommonApiCall("signup", "post", body, false);
        if (response?.status) {
          setSending(false);
          message.destroy();
          DisplayNotification("success", "Success", response?.message);
          setRedirect(true);
        } else {
          message.destroy();
          DisplayNotification("error", "Error", response?.message);
          setSending(false);
        }
      } else {
        message.destroy();
        DisplayNotification("error", "Error", checkEmailResponse?.message);
        setSending(false);
      }
    } catch (error) {
      message.destroy();
      DisplayNotification("error", "Error", "Failed to sign up.");
      setSending(false);
    }
  };

  const mockSendOtp = () => {
    return new Promise((resolve) => {
      setTimeout(() => {
        const success = Math.random() > 0.2; // 80% success rate
        if (success) {
          resolve({
            status: true,
            message: "OTP sent successfully",
            otp: "1234",
          });
        } else {
          resolve({ status: false, message: "Failed to send OTP" });
        }
      }, 2000); // Simulate a 2-second delay
    });
  };

  const sendOtpApiCall = async () => {
    setOtpState((prevState) => ({ ...prevState, sendOtpLoading: true }));
    message.destroy();
    message.loading("Loading", 0);

    // const response = await mockSendOtp();

    const response = await MakeCommonApiCall(
      "send-otp",
      "post",
      {
        contact_no: userData.contact_no,
      },
      true
    );

    if (response?.status) {
      setOtpState((prevState) => ({
        ...prevState,
        sendOtpLoading: false,
        getOtp: response?.status,
      }));
      message.destroy();
      DisplayNotification("success", "Success", response?.message);
      startCountdown();
    } else {
      message.destroy();
      setOtpState((prevState) => ({
        ...prevState,
        sendOtpLoading: false,
        getOtp: false,
      }));
      DisplayNotification("error", "Error", response?.message);
    }
  };

  const startCountdown = () => {
    setCountdown(60);
    const timer = setInterval(() => {
      setCountdown((prevCount) => {
        if (prevCount <= 1) {
          clearInterval(timer);
          setOtpState((prevState) => ({
            ...prevState,
            getOtp: false,
            otpVerifiedFailed: true,
          }));
          return 0;
        }
        return prevCount - 1;
      });
    }, 1000);
  };

  const verifyOtp = async () => {
    message.destroy();
    message.loading("Loading...", 0);

    const checkOtpBody = {
      contact_no: userData.contact_no,
      otp_code: otpState?.otp,
    };

    console.log(checkOtpBody, "-checkOtpBody---checkOtpBody");

    // if (checkOtpBody) {
    //   message.destroy();

    //   setOtpState((prevState) => ({
    //     ...prevState,
    //     otpVerifiedFailed: true,
    //     verifyOtpLoading: false,
    //   }));
    //   setSending(false);
    //   setCountdown(0);
    //   DisplayNotification("error", "Error", "Mock Error ");
    // }

    const checkOtpResponse = await MakeCommonApiCall(
      "verify-otp",
      "post",
      checkOtpBody,
      true
    );
    if (checkOtpResponse?.status) {
      console.log(checkOtpResponse, "-checkOtpResponse---checkOtpResponse");
      setOtpState((prevState) => ({
        ...prevState,
        otpVerifiedFailed: false,
        verifyOtpLoading: false,
      }));
      setSending(false);
      // setCountdown(0);
      onSubmitUser();
    } else {
      setOtpState((prevState) => ({
        ...prevState,
        getOtp: false,
        otpVerifiedFailed: true,
        verifyOtpLoading: false,
      }));
      setSending(false);
      setCountdown(0);
      message.destroy();
      DisplayNotification("error", "Error", checkOtpResponse?.message);
    }
  };

  const setUserDataValue = (key, value) => {
    const common_data = {
      ...userData,
      [key]: value,
    };
    setUserData(common_data);
  };

  if (redirect) {
    return <Redirect to={"/login"} />;
  }

  const onChange = (text) => {
    setOtpState((prevState) => ({ ...prevState, otp: text }));
    console.log("onChange:", text);
  };
  const sharedProps = {
    onChange,
  };

  return (
    <div
      className="app-blank bgi-size-cover bgi-attachment-fixed bgi-position-center bgi-no-repeat"
      style={{
        height: window.innerWidth > 992 ? "100%" : "auto",
        backgroundImage: "url(/assets/media/3303325.jpg)",
        backgroundSize: "cover",
      }}
    >
      <div
        className="d-flex flex-column flex-column-fluid flex-lg-row"
        style={{ height: "100%" }}
      >
        {/*begin::Aside*/}
        <div className="d-flex flex-center w-lg-50 pt-15 pt-lg-0 px-10">
          {/*begin::Aside*/}
          <div className="d-flex flex-center flex-lg-start flex-column">
            {/*begin::Logo*/}
            <div className="mb-7">
              <img
                alt="Logo"
                src="/assets/media/egas-logo-white.png"
                className="w-100"
              />
            </div>
            {/*end::Logo*/}
            {/*begin::Title*/}
            <h2 className="text-white fw-normal m-0 ms-9">
              Branding tools designed for your business
            </h2>
            {/*end::Title*/}
          </div>
          {/*begin::Aside*/}
        </div>
        {/*begin::Aside*/}
        {/*begin::Body*/}
        <div className="d-flex flex-column-fluid flex-lg-row-auto justify-content-center justify-content-lg-end p-12 p-lg-40">
          {/*begin::Card*/}
          <div
            className="d-flex flex-column align-items-stretch flex-center rounded-4 w-md-600px py-20 px-10"
            style={{
              backgroundColor: "#0000002e",
              backdropFilter: "blur(15px)",
              border: "2px solid #ffffff26",
            }}
          >
            {/*begin::Wrapper*/}
            <div className="d-flex flex-center flex-column flex-column-fluid px-lg-10 pb-15 pb-lg-10">
              {/*begin::Form*/}
              <div className="w-100">
                {/*begin::Heading*/}
                <Wrapper>
                  <div className="text-center mb-18">
                    {/*begin::Title*/}
                    <h1 className="text-gray-100 fw-bold fs-2x mb-3">
                      🙌 Welcome in eGas Platform
                    </h1>
                    {/*end::Title*/}
                    {/*begin::Subtitle*/}
                    <div className="text-gray-400 fw-semibold fs-6">
                      Please enter your account details.
                    </div>
                    {/*end::Subtitle=*/}
                  </div>
                  {/*begin::Heading*/}
                  {/*begin::Input group=*/}
                  {!otpState?.getOtp && (
                    <>
                      <div className="row g-9 mb-5 align-items-center">
                        <div className="col-md-6 fv-row">
                          <Input
                            type="text"
                            placeholder="Name"
                            name="name"
                            autoComplete="new-name"
                            className="ant_auth_input bg-transparent h-60px"
                            value={userData?.name}
                            onChange={(e) => {
                              setUserDataValue("name", e.target.value);
                            }}
                          />
                        </div>
                        <div className="col-md-6 fv-row">
                          <Input
                            type="text"
                            placeholder="Email"
                            autoComplete="new-email"
                            className="ant_auth_input bg-transparent h-60px"
                            value={userData?.email}
                            onChange={(e) => {
                              setUserDataValue("email", e.target.value);
                            }}
                          />
                        </div>
                      </div>
                      <div className="row g-9 mb-5 align-items-center">
                        <div className="col-md-6 fv-row">
                          <Input
                            type="text"
                            placeholder="Contact Number"
                            className="ant_auth_input bg-transparent h-60px"
                            value={userData?.contact_no}
                            onChange={(e) => {
                              const inputValue = e.target.value;
                              const isValidContact = /^[0-9]+$/.test(
                                inputValue
                              );

                              if (
                                !inputValue ||
                                (isValidContact && inputValue.length <= 10)
                              ) {
                                setUserDataValue("contact_no", inputValue);
                              }
                            }}
                          />
                        </div>
                        <div className="col-md-6 fv-row">
                          <Input
                            type="text"
                            name="new-addr"
                            autoComplete="new-addr"
                            placeholder="Address"
                            className="ant_auth_input bg-transparent h-60px"
                            value={userData?.address}
                            onChange={(e) => {
                              setUserDataValue("address", e.target.value);
                            }}
                          />
                        </div>
                      </div>
                      <div className="row g-9 mb-5 align-items-center">
                        <div className="col-12">
                          {" "}
                          <label className="fs-6 fw-bold required text-white">
                            Platforms
                          </label>
                        </div>
                        <div className="col-12 fv-row mt-2">
                          <Checkbox.Group
                            options={allPlatform}
                            value={userData?.platforms}
                            onChange={(e) => {
                              console.log(e, "-----e");
                              setUserDataValue("platforms", e);
                            }}
                            className="text-white"
                          />
                        </div>
                      </div>
                      <div className="row g-9 mb-5 align-items-center">
                        <div className="col-md-6 fv-row">
                          <Input.Password
                            type="text"
                            name="password"
                            autoComplete="new-password"
                            placeholder="Password"
                            className="ant_auth_input bg-transparent h-60px"
                            value={password}
                            onChange={(e) => {
                              set_password(e.target.value);
                            }}
                          />
                        </div>
                        <div className="col-md-6 fv-row">
                          <Input.Password
                            type="text"
                            placeholder="Confirm Password"
                            className="ant_auth_input bg-transparent h-60px"
                            value={confirm_pass}
                            onChange={(e) => {
                              set_confirm_pass(e.target.value);
                            }}
                          />
                        </div>
                      </div>
                    </>
                  )}
                </Wrapper>

                {otpState?.getOtp && (
                  <>
                    <p className="text-white text-center">Enter OTP</p>
                    <div className="row g-9 mb-5 align-items-center">
                      <Input.OTP
                        className="ant_auth_input bg-transparent h-160px w-60px fs-2qx text-center mx-1 my-2"
                        length={6}
                        size="large"
                        onKeyDown={(event) => {
                          if (!/[0-9]/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                        onPressEnter={() => {
                          setSending(true);
                          verifyOtp();
                        }}
                        {...sharedProps}
                      />
                    </div>
                  </>
                )}

                {/*end::Input group=*/}
                {/*begin::Wrapper*/}

                {otpState?.getOtp && (
                  <div className="d-grid mt-10">
                    <button
                      onClick={() => {
                        setSending(true);
                        verifyOtp();
                      }}
                      // onClick={() => {
                      //   if (password !== confirm_pass) {
                      //     message.destroy();
                      //     message.warning(
                      //       "Password and Confirm password not matched"
                      //     );
                      //   } else {
                      //     setSending(true);
                      //     const isBlank = Object.keys(userData).find(
                      //       (key) => !userData[key]
                      //     );
                      //     if (isBlank) {
                      //       message.destroy();
                      //       message.warning(`Please enter ${isBlank}`);
                      //       setSending(false);
                      //     } else {
                      //       const isValidEmail =
                      //         /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(
                      //           userData?.email
                      //         );

                      //       if (isValidEmail) {
                      //         verifyOtp();
                      //         // onSubmitUser();
                      //       } else {
                      //         setSending(false);
                      //         message.destroy();
                      //         message.error("Invalid Email");
                      //       }
                      //     }
                      //   }
                      // }}
                      disabled={sending || otpState?.verifyOtpLoading}
                      className="btn btn-primary h-50px"
                    >
                      {/*begin::Indicator label*/}
                      {sending ? (
                        <span
                          className="indicator-progress"
                          style={{ display: "block" }}
                        >
                          Please wait...
                          <span className="spinner-border spinner-border-sm align-middle ms-2" />
                        </span>
                      ) : (
                        <span className="indicator-label">Sign Up</span>
                      )}

                      {/*end::Indicator label*/}
                      {/*begin::Indicator progress*/}

                      {/*end::Indicator progress*/}
                    </button>
                  </div>
                )}

                {/*end::Wrapper*/}
                {/*begin::Submit button*/}
                <div className="d-grid mb-10 mt-10">
                  <button
                    onClick={() => {
                      // Check for blank fields in userData
                      const isBlank = Object.keys(userData).find(
                        (key) => !userData[key]
                      );
                      if (isBlank) {
                        return message.warning(`Please enter ${isBlank}`);
                      }

                      const isValidPlatform = userData?.platforms?.length > 0;

                      if (!isValidPlatform) {
                        return message.warning("Please select platform");
                      }

                      // Validate contact_no and email
                      const isValidContact = /^[0-9]+$/.test(
                        userData?.contact_no || ""
                      );
                      const isValidEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(
                        userData?.email || ""
                      );
                      if (!isValidContact || !isValidEmail) {
                        return message.warning(
                          "Invalid Contact Number or Email"
                        );
                      }

                      // Validate password and confirm_pass
                      if (
                        password.trim() === "" ||
                        confirm_pass.trim() === ""
                      ) {
                        return message.warning(
                          "Password fields cannot be blank"
                        );
                      }
                      if (password !== confirm_pass) {
                        return message.warning(
                          "Password and Confirm password do not match"
                        );
                      }

                      // If all validations pass, proceed with OTP sending
                      sendOtpApiCall();
                    }}
                    disabled={otpState?.sendOtpLoading || countdown > 0}
                    className="btn btn-primary h-50px"
                  >
                    {otpState?.sendOtpLoading ? (
                      <span
                        className="indicator-progress"
                        style={{ display: "block" }}
                      >
                        Please wait...
                        <span className="spinner-border spinner-border-sm align-middle ms-2" />
                      </span>
                    ) : countdown > 0 ? (
                      <span className="indicator-label">
                        Resend OTP in {countdown}s
                      </span>
                    ) : (
                      <span className="indicator-label">
                        {otpState?.otpVerifiedFailed
                          ? "Resend OTP"
                          : "Send OTP"}
                      </span>
                    )}
                  </button>
                </div>

                {/*end::Submit button*/}
                {!otpState?.getOtp && (
                  <div className="d-flex flex-center mt-5 flex-wrap gap-3 fs-base fw-semibold ">
                    {/* <div>sd</div> */}
                    {/*begin::Link*/}
                    <a href="" className="text-light">
                      Forgot Password ?
                    </a>
                    {/*end::Link*/}
                  </div>
                )}
              </div>
              {/*end::Form*/}
            </div>
            {/*end::Wrapper*/}
            {/*begin::Footer*/}
            <div className="d-flex flex-stack px-lg-10 justify-content-center ">
              {otpState?.getOtp ? (
                <div className="text-gray-500 text-center fw-semibold fs-6">
                  Go back to Sign Up Page
                  <Link
                    to="/sign-up"
                    className=" text-white fw-bolder ms-2"
                    style={{ color: "#007c00" }}
                  >
                    Sign Up
                  </Link>
                </div>
              ) : (
                <div className="text-gray-500 text-center fw-semibold fs-6">
                  Already have an account?
                  <Link
                    to="/login"
                    className=" text-white fw-bolder ms-2"
                    style={{ color: "#007c00" }}
                  >
                    Sign In
                  </Link>
                </div>
              )}
              {/*begin::Sign up*/}

              {/*end::Sign up*/}
            </div>
            {/*end::Footer*/}
          </div>
          {/*end::Card*/}
        </div>
        {/*end::Body*/}
      </div>
    </div>
  );
}
export default SignUp;
