import Icons from "../../../../components/icons";
import { GetLinks } from "../../../../core";

export default [
  {
    key: "/channel-integration",
    route_name: "channel-integration",
    menu_name: "Channel Integration",
    icon: <i className="ki-outline ki-data fs-2x p-0" />,
    label: GetLinks("/channel-integration", "Channel Integration"),
  },
];
