import Icons from "../../../../components/icons";
import { GetLinks } from "../../../../core";

export default [
  {
    key: "/report/sales-gstr",
    route_name: "report",
    menu_name: "Report",
    icon: <i className="ki-outline ki-questionnaire-tablet fs-2x p-0" />,
    label: GetLinks("/report", "Report"),
  },
];
