import styled from "styled-components";

export const Wrapper = styled.div`
  position: sticky;
  top: 0px;
  z-index: 9;
  backdrop-filter: blur(5px);
  .ant-layout-header {
    background: rgb(255 255 255 / 0%) !important;
    display: flex;
    align-items: center;
    line-height: 20px;
    justify-content: space-between;
    padding: 39px 0px !important;
  }

  .ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-item,
  .ant-dropdown-menu-submenu .ant-dropdown-menu .ant-dropdown-menu-item,
  .ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-submenu-title,
  .ant-dropdown-menu-submenu
    .ant-dropdown-menu
    .ant-dropdown-menu-submenu-title {
    padding: 2px 8px !important;
  }

  .ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-item {
    padding: 2px 8px !important;
  }

  .ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-item,
  .ant-dropdown-menu-submenu .ant-dropdown-menu .ant-dropdown-menu-item,
  .ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-submenu-title,
  .ant-dropdown-menu-submenu
    .ant-dropdown-menu
    .ant-dropdown-menu-submenu-title {
  }
`;
