import Icons from "../../../../components/icons";
import { GetLinks } from "../../../../core";

export default [
  {
    key: "/manage-user",
    icon: <Icons type="manage-user" />,
    label: GetLinks("/manage-user", "Manage User"),
    admin: true,
  },
];
